import React from 'react';
import { TFunction, useTranslation } from '@wix/yoshi-flow-editor';
import { TagName } from '../../../../utils/tagName.const';
import { DateAndTimeDataHooks } from './dataHooks';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import Text, { TextType } from '../../Text/Text';
import { useEditorContext } from '../../Hooks/useEditorContext';
import { classes } from './DateAndTime.st.css';
import { Service } from '../../../../utils/mappers/service.mapper';
import { Slot } from '@wix/ambassador-availability-calendar/types';
import { ServiceType } from '@wix/bookings-uou-types';

export interface DateAndTimeProps {
  dateRegionalSettingsLocale: string;
  totalNumberOfSessions: number;
  slot: Slot;
  service: Service;
}
const DateAndTime: React.FC<DateAndTimeProps> = ({
  service,
  totalNumberOfSessions,
  slot,
  dateRegionalSettingsLocale,
}) => {
  const { t } = useTranslation();
  const editorContext = useEditorContext();
  const { startDate, timezone } = slot;
  const isCourse = service.type === ServiceType.COURSE;

  const dateAndTime = editorContext.isDummy
    ? t('app.dummy-data.date-and-time')
    : getDateAndTime({
        startDate: startDate!,
        dateRegionalSettingsLocale,
        totalNumberOfSessions,
        t,
        timezone: isCourse ? timezone : undefined,
      });

  return (
    <Text
      className={classes.root}
      type={TextType.Primary}
      data-hook={DateAndTimeDataHooks.SLOT_DATE_AND_TIME}
      tagName={TagName.Paragraph}
    >
      {dateAndTime}
    </Text>
  );
};

const getDateAndTime = ({
  startDate,
  dateRegionalSettingsLocale,
  totalNumberOfSessions,
  t,
  timezone,
}: {
  startDate: string;
  dateRegionalSettingsLocale: string;
  totalNumberOfSessions: number;
  t: TFunction;
  timezone?: string;
}) => {
  const dateAndTimeFormatter = new DateTimeFormatter(
    dateRegionalSettingsLocale!,
    timezone,
  );
  const dateAndTimeFormat = dateAndTimeFormatter.formatDateAndTime(startDate);
  const isServiceHasMultipleSessions = totalNumberOfSessions > 1;

  return isServiceHasMultipleSessions
    ? t('app.booking-details.course.date-and-time.starts.text', {
        date: dateAndTimeFormat,
      })
    : dateAndTimeFormat;
};

export default DateAndTime;
