import React from 'react';
import { StaffMembersDataHooks } from './dataHooks';
import { TagName } from '../../../../utils/tagName.const';
import { StaffMember } from '@wix/bookings-uou-types';
import Text, { TextType } from '../../Text/Text';

export interface StaffMembersProps {
  staffMembers: StaffMember[];
}

const StaffMembers: React.FC<StaffMembersProps> = ({ staffMembers }) => {
  const staffMemberNames = getStaffMembers(staffMembers);

  return (
    <Text
      type={TextType.Secondary}
      data-hook={StaffMembersDataHooks.STAFF_MEMBER}
      tagName={TagName.Paragraph}
    >
      {staffMemberNames}
    </Text>
  );
};

const getStaffMembers = (staffMembers: StaffMember[]) => {
  return staffMembers.map((staffMember) => staffMember.name).join(', ');
};

export default StaffMembers;
