import React from 'react';
import { classes } from './Header.st.css';
import { TagName } from '../../../../utils/tagName.const';
import { Header as FormHeader } from '@wix/ambassador-services-catalog-server/http';
import { HeaderDataHooks } from './dataHooks';
import Text, { TextType } from '../../Text/Text';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../../settingsParams';

export const Header: React.FC<FormHeader> = ({
  title,
  description,
  isDescriptionHidden,
}) => {
  const settings = useSettings();
  const showDescription = !isDescriptionHidden;

  return (
    <div className={classes.root}>
      <SectionTitle
        label={settings.get(settingsParams.formTitle) || title!}
        tagName={TagName.MainHeading}
      />
      {showDescription ? (
        <Text
          type={TextType.Primary}
          className={classes.description}
          data-hook={HeaderDataHooks.DESCRIPTION}
          tagName={TagName.Paragraph}
        >
          {description}
        </Text>
      ) : null}
    </div>
  );
};
